;(function ($) {
  "use strict";

  $(document).ready(function () {

    /* deleting inline styles */
    $('#zapytaj_o_cookies')
      .removeAttr('style')
      .find('[style]')
      .removeAttr('style')
      .end()
      .animate({'opacity': 1}, 300);

    /* foundation init */
    $(document).foundation();

    /* foundation equalizer init */
    $(window).on('resize', function () {
      Foundation.reInit('equalizer');
    });


    /* sliders on homepage */

    // $("#bathrooms").bgswitcher({
    //   images: ["/szablony/1/hansastone/img/sliders/aranzacje-lazienkowe-0.jpg", "/szablony/1/hansastone/img/sliders/aranzacje-lazienkowe-1.jpg", "/szablony/1/hansastone/img/sliders/aranzacje-lazienkowe-2.jpg", "/szablony/1/hansastone/img/sliders/aranzacje-lazienkowe-3.jpg", "/szablony/1/hansastone/img/sliders/aranzacje-lazienkowe-4.jpg"], // Background images
    //   effect: "slide", // fade, blind, clip, slide, drop, hide
    //   interval: 8000, // Interval of switching
    //   loop: true, // Loop the switching
    //   shuffle: false, // Shuffle the order of an images
    //   duration: 2000, // Effect duration
    //   easing: "swing" // Effect easing
    // });
    // $("#gardens").bgswitcher({
    //   images: ["/szablony/1/hansastone/img/sliders/aranzacje-ogrodowe-0.jpg", "/szablony/1/hansastone/img/sliders/aranzacje-ogrodowe-1.jpg", "/szablony/1/hansastone/img/sliders/aranzacje-ogrodowe-2.jpg", "/szablony/1/hansastone/img/sliders/aranzacje-ogrodowe-3.jpg"], // Background images
    //   effect: "slide", // fade, blind, clip, slide, drop, hide
    //   interval: 6000, // Interval of switching
    //   loop: true, // Loop the switching
    //   shuffle: false, // Shuffle the order of an images
    //   duration: 2000, // Effect duration
    //   easing: "swing" // Effect easing
    // });

  });

})(jQuery);